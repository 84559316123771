export const Contributors = [
	"TUNAGE",
	"Nitram",
	"Everdred",
	"Seraphin",
	"marie",
	"paramnesia",
	"Gorgonzola Fernandez",
	"Bobini",
	"DaCat",
	"Highflyer",
	"Fluzzarn",
	"Jag",
	"ERASER",
	"Minty",
	"SJ",
	"Hibiki",
];
