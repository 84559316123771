import { ChangeOpacity } from "util/color-opacity";
import { FormatTables } from "util/misc";
import QuickTooltip from "components/layout/misc/QuickTooltip";
import Divider from "components/util/Divider";
import Muted from "components/util/Muted";
import useLUGPTSettings from "components/util/useLUGPTSettings";
import React from "react";
import { BMS_TABLES, ChartDocument, COLOUR_SET, UGPTSettingsDocument } from "tachi-common";
import RatingSystemPart from "./RatingSystemPart";

export default function BMSOrPMSDifficultyCell({
	chart,
	game,
}: {
	chart: ChartDocument<"bms:7K" | "bms:14K" | "pms:Controller" | "pms:Keyboard">;
	game: "bms" | "pms";
}) {
	const { settings } = useLUGPTSettings() as {
		settings: UGPTSettingsDocument<"bms:7K" | "bms:14K">;
	};

	const hasLevel = chart.data.tableFolders.length > 0;

	const aiLevel = game === "bms" && (chart as ChartDocument<"bms:7K" | "bms:14K">).data.aiLevel;

	let levelText = "No Rating";
	let backgroundColour = hasLevel ? COLOUR_SET.red : COLOUR_SET.gray;

	let filteredTables: Array<{ table: string; level: string }> = [];

	if (hasLevel) {
		let tables = chart.data.tableFolders;

		if (settings?.preferences.gameSpecific.displayTables) {
			filteredTables = tables.filter(
				(e) => !settings.preferences.gameSpecific.displayTables!.includes(e.table)
			);
			tables = tables.filter((e) =>
				settings.preferences.gameSpecific.displayTables!.includes(e.table)
			);
		}

		levelText = FormatTables(tables);

		if (game === "bms") {
			// use bms table colour instead of just red/gray.
			// todo: add pms table colours maybe?
			backgroundColour = FindTableColour(tables);
		}
	}

	return (
		<td
			style={{
				backgroundColor: ChangeOpacity(backgroundColour, 0.2),
			}}
		>
			{!hasLevel && aiLevel ? (
				<>
					<span>No Rating</span>
					<QuickTooltip tooltipContent="This rating has been generated by AI. Don't put too much trust into it.">
						<div>
							<Muted>AI{aiLevel}</Muted>
						</div>
					</QuickTooltip>
				</>
			) : filteredTables.length > 0 ? (
				<QuickTooltip
					tooltipContent={
						<div>
							This chart also appears in these tables, but you have them set to not
							display.
							<Divider />
							{FormatTables(filteredTables)}
						</div>
					}
				>
					<div style={{ textDecoration: "underline dotted" }}>
						{levelText || "Rating Hidden"}
					</div>
				</QuickTooltip>
			) : (
				<span>{levelText}</span>
			)}

			<RatingSystemPart chart={chart} game={game} />
		</td>
	);
}

function FindTableColour(
	tableFolders: ChartDocument<"bms:7K" | "bms:14K">["data"]["tableFolders"]
) {
	const lookup = new Map(BMS_TABLES.map((e) => [e.prefix, e.colour]));

	for (const table of tableFolders) {
		const colour = lookup.get(table.table);

		if (colour) {
			return colour;
		}
	}

	return COLOUR_SET.gray;
}
